import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchTrends } from "../api/api";
import Loading from "../components/Loading";


const TrendingPage = () => {
  const [trends, setTrends] = useState([]);
  
  useEffect(() => {
    const loadTrends = async () => {
      try {
        const data = await fetchTrends();

        if (data && Array.isArray(data.trends)) {
          const trendData = data.trends.map(trend => ({
            htmlBody: trend.html_body,
            url: trend.url_path,
            title: trend.trend_title,
            snippet:trend.snippet,
          }));
          setTrends(trendData);
        } else {
          console.error("Trends data is not in expected format:", data);
        }
      } catch (error) {
        console.error("Failed to fetch trends:", error);
      }
    };

    loadTrends();
  }, []);

  const handleSingleTrend = (url) => {
    console.log("url", url);
  };

  const downloadArticles = async () => {
    const articleUrls = trends.map(trend => trend.url);
    let allContent = "";

    for (const url of articleUrls) {
      try {
        const formattedUrl = `https://thepeoplesvote.us/articles${url}`;
        allContent += `${formattedUrl}\n`; 
      } catch (error) {
        console.error("Error processing URL:", url, error);
      }
    }

    const blob = new Blob([allContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "articlesUrls.txt"; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="text-center py-8">
        <h1 className="text-4xl font-bold leading-tight">
          The Peoples Vote prefers Google’s Trends
        </h1>
        <h1 className="text-4xl font-bold leading-tight">
          and articles for everyone’s latest news, interests, and events.
        </h1>
      </div>
      {trends.length === 0 ? (
        <Loading />
      ) : (
        <>
          {/* <button className="px-4 py-2 text-center font-bold text-xl bg-red-500 ml-[40%] rounded-md" onClick={downloadArticles}>Download All Articles Urls</button> */}
          {trends.map((trend, index) => (
            <div key={index} className="flex pl-[20%]"> {/* Added padding-left to center the text more */}
              <Link to={`/articles${trend.url}`}>
                <div onClick={() => handleSingleTrend(trend.url)} className="text-left">{trend.title}</div>
              </Link>
            </div>
          ))}

  
        </>
      )}
      <div className="text-center mt-8">
        <a href="https://thepeoplesvote.us/" className="text-blue-500 font-bold text-2xl">
          Sponsored by The Peoples Vote
        </a>
      </div>
    </div>
  );
};

export default TrendingPage;
