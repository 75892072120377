import axios from 'axios';

const host="https://api.thepeoplesvote.us"
export const  voteApi = async (formData) => {
    try {
        const response = await axios.post(`${host}/vote`, formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error sending vote data:', error);
        throw error;
    }
};



export const countvote = async () => {
    try {
        const resp = await axios.get(`${host}/vote/count`, {
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 5000,
        });
        
        console.log('Full Response:', resp);

        const data = resp.data;
        console.log('Data:', data);
        
        return data; 

    } catch (error) {
        // Log the error details
        console.log('Error:', error.message);
    }
};



// 

export const fetchTrends = async () => {
    try {
      const response = await fetch('https://api.thepeoplesvote.us/v1/google-trends', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Google trends:', error);
      return null;
    }
  };
  export const fetchSingelTrend = async (url) => {
    try {
      const response = await axios.get(`https://api.thepeoplesvote.us/v1/google-trend?url=${url}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch data: ' + error.message);
    }
  };
  

  export const fetchVotes = async (requestBody) => {
    try {
      const token = localStorage.getItem('adminToken'); 
  
      const response = await axios.post(`${host}/get-votes`, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, 
        },
      });
  
      return response; 
    } catch (error) {
      throw new Error(`Error fetching votes: ${error.response ? error.response.data : error.message}`);
    }
  };

  export const fetchEmails = async (requestBody) => {
    try {
      const token = localStorage.getItem('adminToken'); 
  
      const response = await axios.post(`${host}/emails`,requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; 
    } catch (error) {
      throw new Error(`Error fetching votes: ${error.response ? error.response.data : error.message}`);
    }
  };
  
  

// 
export const loginApi = async (email, password) => {
  try {
    const response = await axios.post(`${host}/login`, { email, password });
    
    if (response.data && response.data.token) {
      const token = response.data.token;

      const expirationTime = Date.now() + 24 * 60 * 60 * 1000; 
      localStorage.setItem('adminToken', token);
      localStorage.setItem('tokenExpiration', expirationTime);
    }

    console.log(response);
    return response;
  } catch (error) {
    console.log(`Error while logging in: ${error.response ? error.response.data : error.message}`);
    throw error; 
  }
};
export const isSessionValid = () => {
  const token = localStorage.getItem('adminToken');
  const expirationTime = localStorage.getItem('tokenExpiration');

  if (token && expirationTime) {
    return Date.now() < expirationTime; 
  }

  return false; 
};

export const logout = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('tokenExpiration');
};
