import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { fetchSingelTrend } from "../api/api";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet";

const TrendDetailPage = () => {
  const location = useLocation();

  // Split the pathname into parts and remove 'articles' from the start
  const pathParts = location.pathname.split('/').filter(part => part !== 'articles');
  
  // Join the parts into a single string and encode the URL properly
  const encodedUrl = encodeURIComponent(pathParts.join('/'));
  console.log("Encoded URL:", encodedUrl);

  const [trendContent, setTrendContent] = useState(null);
  const [title, setTitle] = useState(null);
  const [metaDescription, setMetaDescription] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTrendDetail = async () => {
      try {
        // Fetch the data from your API using the encoded URL
        const data = await fetchSingelTrend(encodedUrl);
        if (data && data.trends && data.trends.html_body) {
          setTrendContent(data.trends.html_body);

          // Extract title and meta description from HTML content
          const parser = new DOMParser();
          const doc = parser.parseFromString(data.trends.html_body, "text/html");

          const extractedTitle = doc.querySelector("title")?.innerText || "";
          const extractedMetaDescription = doc.querySelector("meta[name='description']")?.content || "";

          setTitle(extractedTitle);
          setMetaDescription(extractedMetaDescription);
        } else {
          console.error("Trend not found");
          setTrendContent(null);
        }
      } catch (error) {
        console.error("Failed to fetch trend details:", error);
        setTrendContent(null);
      } finally {
        setLoading(false);
      }
    };

    loadTrendDetail();
  }, [encodedUrl]); // Depend on encodedUrl to re-fetch when it changes

  return (
    <div>
      <Helmet>
        <title>{title || "Loading..."}</title>
        <meta name="description" content={metaDescription || "Loading description..."}/>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        trendContent && (
          <div dangerouslySetInnerHTML={{ __html: trendContent }} />
        )
      )}
    </div>
  );
};

export default TrendDetailPage;
