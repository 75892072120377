import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Herosection from "./pages/Herosection";
import './App.css';
import Enterdetails from "./pages/Enterdetails";
import Vote from "./pages/Vote";
import SorryPage from "./pages/SorryPage";
import SplashScreen from "./pages/SplashScreen";
import Introthree from "./components/Introthree";
import IntroTwo from "./components/IntroTwo";
import Sorry from "./pages/Sorry";
import IntroOne from "./components/IntroOne";
import TrendingTags from "./components/TrendingTags";
import Success from "./pages/Success";
import Intro from "./components/Intro";
import WhiteHeading from "./components/Span/WhiteHeading";
import { useEffect } from "react";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Forgotpassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import TrendingPage from "./pages/Trendingpage";
import TrendDetailPage from "../src/pages/TrendDetailPage";
import ProtectedRoute from "./components/ProtectedRoutes";
import AnalyticsTracker from "./components/AnalyticsTracker";

// New Buy Me A Coffee Button Component
const BuyMeACoffeeButton = () => {
  return (
    <a href="https://buymeacoffee.com/greghalpern" target="_blank" rel="noopener noreferrer" className="fixed bottom-4 right-4 bg-yellow-500 text-white p-3 rounded-full shadow-lg">
      <span role="img" aria-label="coffee cup">☕</span> Buy Me A Coffee
    </a>
  );
};

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();

  const setBodyBackground = () => {
    if (location.pathname === "/") {
      document.body.style.background = "#0A0439";
    } else {
      document.body.style.background = "linear-gradient(90deg, #0964FC 10%, #FF5260E5 40%, #FF5260E5 90%, #f5939be5 100%)";
    }
  };

  useEffect(() => {
    setBodyBackground();
  }, [location]);

  const heading = ({ className }) => (
    <WhiteHeading className={className}>
      Thank You FOR Being Part Of Something Big
    </WhiteHeading>
  );

  const heading1 = ({ className }) => (
    <WhiteHeading className={className}>
      We Have Proven Right Here That Donald Trump Already Has a Landslide Of Voters
    </WhiteHeading>
  );

  const showBuyMeACoffeeButton = !(
    location.pathname === "/dashboard" || location.pathname === "/login" || location.pathname === "/"
  );

  return (
    <>
      <AnalyticsTracker />
      <TrendingTags />

      {/* Conditionally render BuyMeACoffeeButton */}
      {showBuyMeACoffeeButton && <BuyMeACoffeeButton />}

      <Routes>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/" element={<SplashScreen />} />
        <Route path="/home" element={<Herosection navigatePath="/form" buttonText="VOTE NOW" heading={heading1} />} />
        <Route path="/sorry" element={<SorryPage />} />
        <Route path="/form" element={<Enterdetails />} />
        <Route path="/vote" element={<Vote />} />
        <Route path="/success" element={<Success />} />
        <Route path="/articles" element={<TrendingPage />} />
        <Route path="/articles/*" element={<TrendDetailPage />} />

      </Routes>
    </>
  );
}

export default App;
